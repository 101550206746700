import React, { useState } from 'react'
import {
  Text,
  View,
  SafeAreaView,
  TouchableOpacity,
  FlatList,
} from 'react-native'
import { BackIcon } from '../components/Icons'
import styles from '../styles'

export default function Settings({ route, navigation }) {
  return (
    <SafeAreaView style={styles.container} dir="rtl">
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          backgroundColor: '#fff',
          zIndex: 1,
          elevation: 1,
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 1,
          shadowRadius: 0,
          shadowColor: '#0000000d',
        }}
      >
        <View>
          <TouchableOpacity
            style={styles.iconButton}
            activeOpacity={0.5}
            /* onPress={() => navigation.navigate('Home')} */
          >
            {/* <BackIcon stroke="#111" /> */}
          </TouchableOpacity>
        </View>
        <View style={{ flexGrow: 1, alignItems: 'center' }}>
          <Text style={{ fontSize: 17, fontWeight: 500 }}>الإعدادات</Text>
        </View>
        <View>
          <TouchableOpacity
            style={styles.iconButton}
            activeOpacity={0.5}
            /* onPress={onShare} */
          >
            {/* <ShareIcon stroke="#111" /> */}
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ padding: 16 }}>
        <Text style={{ fontSize: 17, marginBottom: 16, textAlign: 'right' }}>
          دليل مصادر اللحوم هو عبارة عن خدمة للبحث في مصادر لحوم مطاعم البحرين،
          يتم تحديثه باستمرار، وهي خدمة مستقلة غير تابعة لأي مؤسسة.
        </Text>
        <Text style={{ fontSize: 17, textAlign: 'right' }}>
          خدمة دليل مصادر اللحوم غير مسؤولة عن أي خطأ في معلومات مصادر اللحوم.
        </Text>
      </View>
    </SafeAreaView>
  )
}
