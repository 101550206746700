import React, { useState } from 'react'
import {
  Text,
  View,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Share,
  Alert,
  ScrollView,
  Linking,
} from 'react-native'
import { BackIcon, ShareIcon, MeatIcon, EggIcon } from '../components/Icons'
import { useQuery } from '@apollo/client'
import { RESTAURANT_QUERY } from '../graphql'
import styles from '../styles'
import Skeleton from '../components/Skeleton'
import moment from 'moment/min/moment-with-locales'
moment.locale('ar')

const RestaurantPlaces = ({ data, navigation }) => {
  if (data[0].name == '')
    return (
      <View style={styles.restaurantPlaces}>
        <Skeleton height={30} />
      </View>
    )
  return (
    <View style={styles.restaurantPlaces}>
      {data.map((place, index) => {
        return (
          <TouchableOpacity
            activeOpacity={0.5}
            onPress={() => navigation.push('Place', { slug: place.slug })}
            key={index}
          >
            <View
              style={{
                borderColor: '#f3f4f6',
                borderWidth: 1,
                paddingHorizontal: 10,
                paddingVertical: 5,
                borderRadius: 12,
              }}
            >
              <Text style={{ fontSize: 14 }}>{place.name}</Text>
            </View>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

const InfoCard = ({ icon, title, data }) => (
  <View style={styles.infoContainer}>
    <View style={styles.infoCard}>
      {icon}
      <Text style={{ color: '#9ca3af', fontSize: 16 }}>{title}</Text>
    </View>
    {data ? (
      <></>
    ) : (
      <View style={styles.infoList}>
        <Skeleton />
      </View>
    )}
    {data.length > 0 ? (
      <View style={styles.infoList}>
        {data.map((source, index) => {
          return (
            <View
              style={{
                backgroundColor: '#f3f4f6',
                paddingHorizontal: 12,
                paddingVertical: 6,
                borderRadius: 12,
              }}
              key={index}
            >
              <Text style={{ fontSize: 16, fontWeight: 500 }}>
                {source.name}
              </Text>
            </View>
          )
        })}
      </View>
    ) : (
      <View style={styles.infoList}>
        <Text style={{ fontSize: 16, fontWeight: 500 }}>غير معروف</Text>
      </View>
    )}
  </View>
)

export default function Restaurant({ route, navigation }) {
  const { slug } = route.params
  const [restaurant, setRestaurant] = useState({
    id: '',
    name: '',
    slug: slug,
    logo: { url: '' },
    places: [{ slug: '', name: '' }],
    chickenSources: [{ name: '' }],
    meatSources: [{ name: '' }],
    instagram: '',
    updatedAt: '',
  })

  const { result, loading, error } = useQuery(RESTAURANT_QUERY, {
    variables: { slug: slug },
    onCompleted: (data) => {
      if (data.restaurant === null) return navigation.navigate('Home')
      setRestaurant(data.restaurant)
      navigation.setOptions({ title: data.restaurant.name })
    },
  })

  //if (!loading && !restaurant) return navigation.navigate('Home')

  const onShare = async () => {
    if (!loading && !error) {
      try {
        const result = await Share.share({
          message: 'دليل اللحوم - ' + restaurant.name,
          url: 'https://lohom.org/r/' + slug,
        })
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            // shared with activity type of result.activityType
          } else {
            // shared
          }
        } else if (result.action === Share.dismissedAction) {
          // dismissed
        }
      } catch (error) {
        Alert.alert(error.message)
      }
    }
  }

  const instagramURL = 'instagram://user?username=' + restaurant.instagram

  const onInstagramPress = () =>
    Linking.canOpenURL(instagramURL)
      .then((supported) =>
        Linking.openURL(
          supported
            ? instagramURL
            : 'https://instagram.com/' + restaurant.instagram
        )
      )
      .catch((err) => console.error('An error occurred', err))

  const HeaderView = () => (
    <View
      style={{
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <View>
        <TouchableOpacity
          style={styles.iconButton}
          activeOpacity={0.5}
          onPress={() =>
            navigation.canGoBack()
              ? navigation.goBack()
              : navigation.navigate('Home')
          }
        >
          <BackIcon stroke="#111" />
        </TouchableOpacity>
      </View>
      <View style={{ flexGrow: 1, alignItems: 'center' }}></View>
      <View>
        <TouchableOpacity
          style={styles.iconButton}
          activeOpacity={0.5}
          onPress={onShare}
        >
          <ShareIcon stroke="#111" />
        </TouchableOpacity>
      </View>
    </View>
  )

  return (
    <SafeAreaView style={styles.container} dir="rtl">
      <ScrollView stickyHeaderIndices={[0]}>
        <HeaderView />
        <View style={styles.restaurantHeader}>
          <View style={styles.restaurantHeaderLogo}>
            {restaurant.logo.url !== '' ? (
              <Image
                source={{ uri: restaurant.logo.url }}
                style={{
                  width: 120,
                  height: 120,
                  borderWidth: 1,
                  borderRadius: 24,
                  borderColor: '#0000000d',
                }}
              />
            ) : (
              <></>
            )}
          </View>
          <Text style={{ fontSize: 19, fontWeight: 500 }}>
            {restaurant.name || <Skeleton height={24} />}
          </Text>
          <Text
            style={{
              fontSize: 15,
              direction: 'ltr',
              color: '#9ca3af',
              marginTop: 6,
            }}
            onPress={onInstagramPress}
          >
            {restaurant.instagram ? '@' : ''}
            {restaurant.instagram || <Skeleton height={18} />}
          </Text>
        </View>
        <RestaurantPlaces data={restaurant.places} navigation={navigation} />
        <InfoCard
          icon={<EggIcon stroke="#9ca3af" />}
          title="مصادر الدجاج"
          data={restaurant.chickenSources}
        />
        <InfoCard
          icon={<MeatIcon stroke="#9ca3af" />}
          title="مصادر اللحوم"
          data={restaurant.meatSources}
        />
        <Text
          style={{
            fontSize: 15,
            color: '#9ca3af',
            marginTop: 32,
            alignSelf: 'center',
          }}
        >
          {restaurant.updatedAt ? (
            'آخر تحديث للمعلومات' +
            moment(restaurant.updatedAt).format(' D MMM YYYY')
          ) : (
            <></>
          )}
        </Text>
      </ScrollView>
    </SafeAreaView>
  )
}
