import { StyleSheet, StatusBar } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    marginTop: StatusBar.currentHeight,
  },
  input: {
    borderWidth: 0,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 36,
    backgroundColor: '#f3f4f6',
    color: '#111827',
    fontSize: 17,
    textAlign: 'right',
    alignItems: 'flex-start',
    outlineStyle: 'none',
  },
  searchField: {
    paddingHorizontal: 16,
    alignSelf: 'stretch',
    backgroundColor: '#fff',
  },
  restaurantCard: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    flexDirection: 'row',
    /* marginVertical: 8, */
    marginBottom: 16,
    marginHorizontal: 16,
  },
  restaurantCardLogo: {
    width: 60,
    height: 60,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 12,
    backgroundColor: '#f1f1f1',
    borderWidth: 0,
    borderColor: '#f3f4f6',
    /* shadowColor: '#000',
    shadowRadius: 0,
    shadowOffset: { width: -1, height: -1 }, */
  },
  restaurantCardText: {
    alignItems: 'flex-start',
    flexGrow: 1,
    gap: 4,
  },
  iconButton: {
    width: 54,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  restaurantHeader: {
    alignItems: 'center',
    marginTop: -38,
  },
  restaurantHeaderLogo: {
    marginBottom: 16,
    width: 120,
    height: 120,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 24,
    backgroundColor: '#f1f1f1',
    borderWidth: 0,
    borderColor: '#f1f1f1',
  },
  infoContainer: {
    marginTop: 16,
    marginStart: 16,
  },
  infoCard: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  infoList: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#f3f4f6',
    flexWrap: 'wrap',
    gap: 8,
    paddingVertical: 16,
    paddingEnd: 16,
    fontWeight: 500,
  },
  restaurantPlaces: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'center',
    padding: 16,
  },
  /* Place Select */
  placeSelectButton: {
    backgroundColor: '#f3f4f6',
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 12,
  },
  placeSelectButtonSelected: {
    backgroundColor: '#111827',
  },
  placeSelectText: {
    fontSize: 14,
    color: '#000000',
  },
  placeSelectTextSelected: {
    color: '#ffffff',
  },
  /* Absolute Icons */
  startIconView: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: 40,
    start: 0,
  },
  endIconView: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: 40,
    end: 0,
  },
})
