import React from 'react'
import { Provider } from 'react-redux'
import store from './store'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { I18nManager, View } from 'react-native'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  from,
  HttpLink,
} from '@apollo/client'
import { StatusBar } from 'expo-status-bar'
import Home from './screens/Home'
import Restaurant from './screens/Restaurant'
import Place from './screens/Place'
import Settings from './screens/Settings'
import {
  HomeIcon,
  InfoIcon,
  RefreshIcon,
  SearchIcon,
  SettingsIcon,
} from './components/Icons'
import Random from './screens/Random'

I18nManager.forceRTL(true)
I18nManager.allowRTL(true)

const token =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NjQwMzU5NjMsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtc2hhcmVkLWV1YzEtMDIuaHlncmFwaC5jb20vdjIvY2w4ZnVmMjByM3lqYjAxdWUxcTVwM3d0ZS9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMDgzOWNmNWYtYzRjZi00OTE4LTgzYjQtMTBmOTk5NzczNmExIiwianRpIjoiY2w4ZzQxcXBwNDV5czAxdXBiamtjOWRlbiJ9.y-oB_xzdEcucu1mm8WQP3om9pqta8n7WMgf6vPho4Ylg58jZHylzIUfDnICLd30RTm5bwuyyDfQgPbyBFvAUBtKIFze4GV5SEXfdG2D8HMqEh_P3Kw_dFHLe8ZczW5252DPVI0Wj7v9_EG5jf-0ODnfRNFXWHbThy8UMihFq8EpzpAxoI8_62lCYFl1XbFBzhbzCtXDdmFhDzddO7YkGtAfZREi5_wFd55fMyoSnEdgqWFhxwthMwCH96yRNWVcnIbpnMCEo3tk5nuBW2eqhYuQoCGpw1XaA1vw5Wor8HH_4RkoJQYE_ZF0BJ4RXoZn200VE4wPEzILLpBRIpxrI6W4j4omZtnj3AWz5XtNzqVQDVru48ND9vvGpj1QCoU8HFKK6FaGwZoQ_uRjmOT9-1oxrx0s4fTJDYqI2ENGZBPoZ8U5E55N4pLOKePbjwxlVQsC7o7lAJu-l85U_dGxp-ssOeVsyuoOYYPjf-3KQnfbuScLFL-zu2y4D46DxDIee0mbkStKWT0_cvFfBaEjvMS-QVEnMoSoTYmz55pKXp63VpG9Bsz72m8HpuzsEJ_fvzSreNp6PCbUC6wk-eLFGXnkRNgSpiuUps99zCtXSWa-3_PGQK7lekn1qlCXZh1O5-za8G7Lcl0ZTMeL-R2kzoVH3pdIigYecAdpn1QatCMA'

const additiveLink = from([
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    }))
    return forward(operation)
  }),
  new HttpLink({
    uri: 'https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cl8fuf20r3yjb01ue1q5p3wte/master',
  }),
])

const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache(),
})

const Stack = createNativeStackNavigator()

const HomeStack = createNativeStackNavigator()
function HomeScreenStack() {
  return (
    <HomeStack.Navigator
      initialRouteName="Home"
      screenOptions={{ headerShown: false }}
    >
      <HomeStack.Screen name="Home" component={Home} />
      <HomeStack.Screen name="Restaurant" component={Restaurant} />
      <HomeStack.Screen name="Place" component={Place} />
      <HomeStack.Screen name="Settings" component={Settings} />
    </HomeStack.Navigator>
  )
}

const Tab = createBottomTabNavigator()

export default function App() {
  const linking = {
    prefixes: ['https://lohom.org', 'lohom://'],
    config: {
      screens: {
        Home: {
          screens: {
            Restaurant: 'r/:slug',
            Place: 'p/:slug',
          },
        },
        Random: 'random',
        Settings: 'settings',
      },
    },
  }
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <StatusBar style="auto" backgroundColor="white" />
        <View style={{ flex: 1, alignItems: 'center' }}>
          <View
            style={{
              flex: 1,
              width: '100%',
              maxWidth: 500,
              borderRightWidth: 1,
              borderLeftWidth: 1,
              borderColor: 'rgba(0, 0, 0, 0.05)',
            }}
          >
            <NavigationContainer
              linking={linking}
              documentTitle={{
                formatter: (options, route) =>
                  `${options.title ? options.title + ' - ' : ''}دليل اللحوم`,
              }}
            >
              {/* <Stack.Navigator
          initialRouteName="Home"
          screenOptions={{ headerShown: false }}
        >
          <Stack.Screen name="Home" component={Home} />
          <Stack.Screen name="Restaurant" component={Restaurant} />
          <Stack.Screen name="Place" component={Place} />
          <Stack.Screen name="Settings" component={Settings} />
        </Stack.Navigator> */}
              <Tab.Navigator
                screenOptions={({ route }) => ({
                  tabBarIcon: ({ color }) => {
                    if (route.name === 'Home') {
                      return <HomeIcon stroke={color} />
                    } else if (route.name === 'Random') {
                      return <RefreshIcon stroke={color} />
                    } else if (route.name === 'Settings') {
                      return <InfoIcon stroke={color} />
                    }
                  },
                  headerShown: false,
                  tabBarActiveTintColor: '#111',
                  tabBarShowLabel: false,
                  tabBarStyle: {
                    borderTopColor: '#0000000d',
                  },
                })}
              >
                <Tab.Screen
                  name="Home"
                  component={HomeScreenStack}
                  options={{
                    tabBarLabel: '',
                  }}
                />
                <Tab.Screen
                  name="Random"
                  component={Random}
                  options={{
                    tabBarLabel: '',
                  }}
                />
                <Tab.Screen
                  name="Settings"
                  component={Settings}
                  options={{
                    tabBarLabel: '',
                  }}
                />
              </Tab.Navigator>
            </NavigationContainer>
          </View>
        </View>
      </Provider>
    </ApolloProvider>
  )
}
