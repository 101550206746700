import { View } from 'react-native'
import Skeleton from '../components/Skeleton'

export default function RestaurantItemSkeleton({ count }) {
  const Placeholder = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 16,
          marginBottom: 16,
          marginHorizontal: 16,
        }}
      >
        <Skeleton width={60} height={60} radius={12} />
        <View
          style={{
            alignItems: 'flex-start',
            flexGrow: 1,
            gap: 8,
          }}
        >
          <Skeleton width={120} height={20} radius={6} />
          <Skeleton width={80} height={16} radius={6} />
        </View>
      </View>
    )
  }
  return [...Array(count)].map((_, index) => <Placeholder key={index} />)
}
