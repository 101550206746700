import React, { useEffect, useRef, useState, useCallback } from 'react'
import {
  Text,
  View,
  TextInput,
  SafeAreaView,
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
} from 'react-native'
import { XIcon, SearchIcon, SettingsIcon } from '../components/Icons'
import { useQuery } from '@apollo/client'
import { ALL_RESTAURANTS_QUERY } from '../graphql'
import styles from '../styles'
import RestaurantItem from '../components/RestaurantItem'
import RestaurantItemSkeleton from '../components/RestaurantItemSkeleton'
import { load } from '../store'
import { useDispatch } from 'react-redux'

function normalizeText(str) {
  var output = str
  output = output.replaceAll(/([أآإ])/g, 'ا')
  output = output.replaceAll(/([ڤ])/g, 'ف')

  return output
}

const HeaderView = ({ showBottomBorder, onSearch, navigation }) => (
  <View
    style={{
      alignItems: 'center',
      flexDirection: 'row',
      backgroundColor: '#fff',
      zIndex: 1,
      elevation: showBottomBorder ? 1 : 0,
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: showBottomBorder ? 1 : 0,
      shadowRadius: 0,
      shadowColor: '#0000000d',
    }}
  >
    <View style={styles.iconButton}>
      {/* <TouchableOpacity
        style={styles.iconButton}
        activeOpacity={0.5}
        onPress={() => navigation.push('Settings')}
      >
        <SettingsIcon stroke="#111" />
      </TouchableOpacity> */}
    </View>
    <View style={{ flexGrow: 1, alignItems: 'center' }}>
      <Text style={{ fontSize: 17, fontWeight: 500 }}>دليل اللحوم</Text>
    </View>
    <View style={{ width: 54, height: 54 }}>
      {showBottomBorder ? (
        <TouchableOpacity
          style={styles.iconButton}
          activeOpacity={0.5}
          onPress={onSearch}
        >
          <SearchIcon stroke="#111" />
        </TouchableOpacity>
      ) : (
        <></>
      )}
    </View>
  </View>
)

const SearchInput = ({ inputRef, count, value, onSearch, onClear }) => {
  const ClearButton = () => {
    return value != '' ? (
      <TouchableOpacity
        activeOpacity={0.5}
        style={styles.endIconView}
        onPress={onClear}
      >
        <XIcon stroke="#9ca3af" />
      </TouchableOpacity>
    ) : (
      <></>
    )
  }
  return (
    <View>
      <TextInput
        ref={inputRef}
        dir="rtl"
        style={styles.input}
        placeholder={'بحث' + (count ? ' في ' + count + ' مطعم' : '') + '...'}
        placeholderTextColor="#9ca3af"
        inputMode="search"
        autoCorrect={false}
        value={value}
        onChangeText={onSearch}
      />
      <View style={styles.startIconView}>
        <SearchIcon stroke="#9ca3af" />
      </View>
      <ClearButton />
    </View>
  )
}

const PlaceSelectButton = ({ place, selectedPlace, onPlaceSelect }) => {
  const handlePress = useCallback(() => {
    onPlaceSelect(place.id)
  }, [place, onPlaceSelect])

  const buttonStyles = [
    styles.placeSelectButton,
    selectedPlace == place.id ? styles.placeSelectButtonSelected : null,
  ]
  const textStyles = [
    styles.placeSelectText,
    selectedPlace == place.id ? styles.placeSelectTextSelected : null,
  ]

  return (
    <TouchableOpacity activeOpacity={0.5} onPress={handlePress}>
      <View style={buttonStyles}>
        <Text style={textStyles}>{place.name}</Text>
      </View>
    </TouchableOpacity>
  )
}
const PlacesList = ({ data, selectedPlace, onPlaceSelect }) => (
  <FlatList
    keyboardShouldPersistTaps="handled"
    keyboardDismissMode="on-drag"
    data={data}
    style={{
      flex: 1,
      paddingTop: 16,
      marginHorizontal: -16,
    }}
    ListHeaderComponent={() => <View style={{ width: 16 }} />}
    ListFooterComponent={() => <View style={{ width: 16 }} />}
    ItemSeparatorComponent={() => <View style={{ width: 8 }} />}
    showsHorizontalScrollIndicator={false}
    renderItem={({ item }) => (
      <PlaceSelectButton
        place={item}
        selectedPlace={selectedPlace}
        onPlaceSelect={onPlaceSelect}
      />
    )}
    keyExtractor={(item, index) => index}
    horizontal
  />
)

export default function Home({ navigation }) {
  const dispatch = useDispatch()
  const { loading, error, data } = useQuery(ALL_RESTAURANTS_QUERY, {
    variables: { pageSize: 100, lat: 0.0, long: 0.0 },
    onCompleted: (data) => {
      setRestaurants(data.restaurants)
      setFilteredRestaurants(data.restaurants)
      setPlacedRestaurants(data.restaurants)
      setPlaces(data.places)

      dispatch(load(data.restaurants))
    },
  })

  const [restaurants, setRestaurants] = useState([])
  const [filteredRestaurants, setFilteredRestaurants] = useState([])
  const [searchText, setSearchText] = useState('')

  const [places, setPlaces] = useState([])
  const [selectedPlace, setSelectedPlace] = useState('')
  const [placedRestaurants, setPlacedRestaurants] = useState([])

  const [showBottomBorder, setShowBottomBorder] = useState(false)

  const searchInputRef = useRef()
  const restaurantsListRef = useRef()

  // Handling Search Field
  const handleSearch = (text) => {
    setSearchText(text)
  }
  /* useEffect(() => {
    setFilteredRestaurants(
      restaurants.filter((item) => {
        return (
          normalizeText(item.name.toLowerCase()).indexOf(
            normalizeText(searchText.toLowerCase())
          ) > -1 ||
          item.instagram.toLowerCase().indexOf(searchText.toLowerCase()) > -1
        )
      })
    )
  }, [searchText, restaurants]) */

  // Handling Scroll
  const handleScroll = (event) => {
    const y = event.nativeEvent.contentOffset.y
    setShowBottomBorder(y > 42 ? true : false)
  }

  // Handling Places Select
  const onPlaceSelect = (id) => {
    setSelectedPlace(selectedPlace == id ? '' : id)
  }
  /* useEffect(() => {
    setPlacedRestaurants(
      filteredRestaurants.filter((item) => {
        const placeIds = []
        item.places.forEach((e) => {
          placeIds.push(e.id)
        })
        return placeIds.includes(selectedPlace)
      })
    )
  }, [selectedPlace, filteredRestaurants]) */

  useEffect(() => {
    let filtered = restaurants
    if (selectedPlace) {
      filtered = filtered.filter((restaurant) => {
        const placeIds = []
        restaurant.places.forEach((e) => {
          placeIds.push(e.id)
        })
        return placeIds.includes(selectedPlace)
      })
    }
    if (searchText) {
      filtered = filtered.filter(
        (restaurant) =>
          normalizeText(restaurant.name.toLowerCase()).indexOf(
            normalizeText(searchText.toLowerCase())
          ) > -1 ||
          restaurant.instagram.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1
      )
    }
    setFilteredRestaurants(filtered)
  }, [restaurants, searchText, selectedPlace])

  if (error) console.log(error)

  return (
    <View style={styles.container} dir="rtl">
      <SafeAreaView />
      <HeaderView
        showBottomBorder={showBottomBorder}
        onSearch={() => {
          restaurantsListRef.current.scrollToIndex({ index: 0, animated: true })
          searchInputRef.current.focus()
        }}
        navigation={navigation}
      />
      <FlatList
        ref={restaurantsListRef}
        keyboardShouldPersistTaps="handled"
        keyboardDismissMode="on-drag"
        StickyHeaderComponent={HeaderView}
        ListEmptyComponent={
          loading ? <RestaurantItemSkeleton count={10} /> : null
        }
        ListHeaderComponent={
          <View
            style={{
              padding: 16,
              paddingTop: 0,
              alignSelf: 'stretch',
              backgroundColor: '#fff',
            }}
          >
            <SearchInput
              inputRef={searchInputRef}
              count={restaurants.length}
              onSearch={handleSearch}
              value={searchText}
              onClear={() => setSearchText('')}
            />
            <PlacesList
              data={places}
              selectedPlace={selectedPlace}
              onPlaceSelect={onPlaceSelect}
            />
            {/* {loading ? (
              <ActivityIndicator style={{ marginTop: 16 }} />
            ) : (
              <View />
            )} */}
          </View>
        }
        style={{ flex: 1 }}
        getItemLayout={(data, index) => ({
          length: 76,
          offset: 76 * index,
          index,
        })}
        data={filteredRestaurants}
        renderItem={({ item }) => (
          <RestaurantItem data={item} navigation={navigation} />
        )}
        keyExtractor={(item, index) => index}
        onScroll={handleScroll}
        snapToOffsets={[86]}
        snapToEnd={false}
      />
    </View>
  )
}
