import { View } from 'react-native'

export default function Skeleton({ width, height, radius }) {
  return (
    <View
      style={{
        backgroundColor: '#f3f3f3',
        width: width ?? 70,
        height: height ?? 22,
        borderRadius: radius ?? 6,
      }}
    ></View>
  )
}
