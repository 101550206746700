import gql from 'graphql-tag'

export const ALL_RESTAURANTS_QUERY = gql`
  query Restaurants($pageSize: Int!, $lat: Float!, $long: Float!) {
    restaurants(first: $pageSize, orderBy: createdAt_DESC) {
      id
      name
      slug
      logo {
        url(transformation: { image: { resize: { width: 120 } } })
      }
      instagram
      places {
        id
      }
      location {
        latitude
        longitude
        distance(from: { latitude: $lat, longitude: $long })
      }
    }
    places(first: 500) {
      id
      name
      location {
        distance(from: { latitude: $lat, longitude: $long })
      }
      restaurants {
        ... on Restaurant {
          id
        }
      }
    }
  }
`
export const RESTAURANT_QUERY = gql`
  query Restaurant($slug: String!) {
    restaurant(where: { slug: $slug }) {
      id
      name
      logo {
        url
      }
      places {
        id
        slug
        name
      }
      chickenSources {
        name
      }
      meatSources {
        name
      }
      instagram
      updatedAt
    }
  }
`
export const PLACE_QUERY = gql`
  query Place($slug: String!, $pageSize: Int!) {
    place(where: { slug: $slug }) {
      id
      slug
      name
      restaurants(first: $pageSize) {
        ... on Restaurant {
          id
          name
          slug
          instagram
          logo {
            url(transformation: { image: { resize: { width: 120 } } })
          }
        }
      }
    }
  }
`
