import React, { useState } from 'react'
import {
  Text,
  View,
  SafeAreaView,
  TouchableOpacity,
  FlatList,
} from 'react-native'
import { BackIcon } from '../components/Icons'
import { useQuery } from '@apollo/client'
import { PLACE_QUERY } from '../graphql'
import styles from '../styles'
import RestaurantItem from '../components/RestaurantItem'
import Skeleton from '../components/Skeleton'
import RestaurantItemSkeleton from '../components/RestaurantItemSkeleton'

export default function Place({ route, navigation }) {
  const { slug } = route.params
  const { result, loading, error } = useQuery(PLACE_QUERY, {
    variables: { slug: slug, pageSize: 100 },
    onCompleted: (data) => {
      if (data.place === null) return navigation.navigate('Home')
      setPlace(data.place)
      navigation.setOptions({ title: data.place.name })
    },
  })
  const [place, setPlace] = useState({
    id: '',
    slug: slug,
    name: '',
    restaurants: {
      id: '',
      slug: '',
      name: '',
      logo: { url: '' },
      instagram: '',
    },
  })

  return (
    <SafeAreaView style={styles.container} dir="rtl">
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <View>
          <TouchableOpacity
            style={styles.iconButton}
            activeOpacity={0.5}
            onPress={() =>
              navigation.canGoBack()
                ? navigation.goBack()
                : navigation.navigate('Home')
            }
          >
            <BackIcon stroke="#111" />
          </TouchableOpacity>
        </View>
        <View style={{ flexGrow: 1, alignItems: 'center' }}>
          <Text style={{ fontSize: 17, fontWeight: 500 }}>
            {place.name || <Skeleton />}
          </Text>
        </View>
        <View>
          <TouchableOpacity
            style={styles.iconButton}
            activeOpacity={0.5}
            /* onPress={onShare} */
          >
            {/* <ShareIcon stroke="#111" /> */}
          </TouchableOpacity>
        </View>
      </View>
      {loading ? (
        <View style={{ marginTop: 8 }}>
          <RestaurantItemSkeleton count={2} />
        </View>
      ) : null}
      <FlatList
        style={{ marginTop: 8 }}
        data={place.restaurants}
        renderItem={({ item }) => (
          <RestaurantItem data={item} navigation={navigation} />
        )}
        keyExtractor={(item, index) => index}
      />
    </SafeAreaView>
  )
}
