import { memo } from 'react'
import { Text, View, Image, TouchableOpacity } from 'react-native'
import { ChevronIcon } from './Icons'
import styles from '../styles'
import Skeleton from '../components/Skeleton'

function RestaurantItem({ data, navigation }) {
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={() => navigation.push('Restaurant', { slug: data.slug })}
    >
      <View style={styles.restaurantCard}>
        <View style={styles.restaurantCardLogo}>
          <Image
            source={{ uri: data.logo.url }}
            style={{
              width: 60,
              height: 60,
              borderWidth: 1,
              borderRadius: 12,
              borderColor: '#0000000d',
            }}
          />
        </View>
        <View style={styles.restaurantCardText}>
          <Text style={{ fontSize: 17 }}>{data.name || <Skeleton />}</Text>
          <Text style={{ fontSize: 15, direction: 'ltr', color: '#9ca3af' }}>
            {'@' + data.instagram || <Skeleton />}
          </Text>
        </View>
        <View>
          <ChevronIcon stroke="#ddd" />
        </View>
      </View>
    </TouchableOpacity>
  )
}

function areEqual(prevProps, nextProps) {
  return prevProps.data.id === nextProps.data.id
}

export default memo(RestaurantItem, areEqual)
