import { View, Text, TouchableOpacity } from 'react-native'

export default function CustomButton({ title, onPress, width, height }) {
  return (
    <TouchableOpacity activeOpacity={0.5} onPress={onPress}>
      <View
        style={{
          backgroundColor: '#ff2d55',
          width: width ?? '100%',
          height: height ?? 40,
          borderRadius: 10,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={{ color: '#ffffff', fontWeight: '500', fontSize: 16 }}>
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  )
}
