import React, { useState } from 'react'
import {
  Text,
  View,
  SafeAreaView,
  TouchableOpacity,
  FlatList,
  Image,
  Button,
} from 'react-native'
import { BackIcon } from '../components/Icons'
import styles from '../styles'
import { useSelector } from 'react-redux'
import CustomButton from '../components/CustomButton'

const RandomRestaurantView = ({ data, navigation }) => {
  return data !== null ? (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={() =>
        navigation.navigate('Home', {
          screen: 'Restaurant',
          params: { slug: data.slug },
        })
      }
    >
      <View style={{ alignItems: 'center' }}>
        <View style={styles.restaurantHeaderLogo}>
          {data.logo.url !== '' ? (
            <Image
              source={{ uri: data.logo.url }}
              style={{
                width: 120,
                height: 120,
                borderWidth: 1,
                borderRadius: 24,
                borderColor: '#0000000d',
              }}
            />
          ) : (
            <></>
          )}
        </View>
        <Text style={{ fontSize: 19, fontWeight: 500 }}>{data.name}</Text>
      </View>
    </TouchableOpacity>
  ) : (
    <></>
  )
}

export default function Random({ route, navigation }) {
  const rlist = useSelector((state) => state.restaurants)
  const [randomRest, setRandomRest] = useState(null)
  return (
    <SafeAreaView style={styles.container} dir="rtl">
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          backgroundColor: '#fff',
          zIndex: 1,
          elevation: 1,
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 1,
          shadowRadius: 0,
          shadowColor: '#0000000d',
        }}
      >
        <View>
          <TouchableOpacity
            style={styles.iconButton}
            activeOpacity={0.5}
            /* onPress={() => navigation.navigate('Home')} */
          >
            {/* <BackIcon stroke="#111" /> */}
          </TouchableOpacity>
        </View>
        <View style={{ flexGrow: 1, alignItems: 'center' }}>
          <Text style={{ fontSize: 17, fontWeight: 500 }}>مطعم عشوائي</Text>
        </View>
        <View>
          <TouchableOpacity
            style={styles.iconButton}
            activeOpacity={0.5}
            /* onPress={onShare} */
          >
            {/* <ShareIcon stroke="#111" /> */}
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ padding: 16, flex: 1 }}>
        <View style={{ alignItems: 'center' }}>
          <Text>محتار اليوم ؟</Text>
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <RandomRestaurantView data={randomRest} navigation={navigation} />
        </View>
        <View>
          {/* <Button
            title="بحث"
            onPress={() => {
              const len = Math.floor(Math.random() * rlist.value.length)
              setRandomRest(rlist.value[len])
            }}
          /> */}
          <CustomButton
            title="بحث"
            onPress={() => {
              const len = Math.floor(Math.random() * rlist.value.length)
              setRandomRest(rlist.value[len])
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}
