import { createSlice, configureStore } from '@reduxjs/toolkit'

const restaurantsSlice = createSlice({
  name: 'restaurants',
  initialState: {
    value: [],
  },
  reducers: {
    load: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { load } = restaurantsSlice.actions

export default configureStore({
  reducer: { restaurants: restaurantsSlice.reducer },
})
