import * as React from 'react'
import Svg, { Path, Ellipse, Circle } from 'react-native-svg'

export const ChevronIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Path d="m14 18-5-6 5-6" />
  </Svg>
)

export const BackIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Path d="m16 6 5 6-5 6M21 12H3" />
  </Svg>
)

export const ShareIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Path d="M4 15.5V18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2.5M7 8l5-4 5 4M12 4v11" />
  </Svg>
)

export const EggIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Path d="m8.4 4.733-.951.987a9.64 9.64 0 0 0-1.02 12.13c2.68 3.92 8.462 3.92 11.141 0a9.64 9.64 0 0 0-1.019-12.13l-.952-.987a5 5 0 0 0-7.198 0Z" />
  </Svg>
)

export const MeatIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Ellipse
      cx={8.425}
      cy={8.425}
      rx={3.5}
      ry={7}
      transform="rotate(45 8.425 8.425)"
    />
    <Path
      strokeLinecap="round"
      d="m13.728 3.828 2.07 2.833a6.538 6.538 0 0 1-.656 8.481v0a6.538 6.538 0 0 1-8.481.656l-2.833-2.07"
    />
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m16.438 14.317.825.825 1.16-.386a1.64 1.64 0 0 1 1.678.396v0a1.64 1.64 0 0 1-.55 2.681l-1.227.491-.49 1.227a1.64 1.64 0 0 1-2.682.55v0a1.64 1.64 0 0 1-.397-1.678l.387-1.16-.825-.825m-6.6-7.306 1.415-1.414"
    />
  </Svg>
)

export const SearchIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Circle cx={11} cy={11} r={7} />
    <Path d="m20 20-4-4" />
  </Svg>
)

export const XIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Circle cx={12} cy={12} r={9} />
    <Path d="m9 15 6-6M9 9l6 6" />
  </Svg>
)

export const SettingsIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Path d="M10.539 3.563a2 2 0 0 1 2.922 0l.789.844a2 2 0 0 0 1.528.633l1.154-.039A2 2 0 0 1 19 7.068l-.04 1.154a2 2 0 0 0 .634 1.528l.844.789a2 2 0 0 1 0 2.922l-.844.789a2 2 0 0 0-.633 1.528L19 16.932A2 2 0 0 1 16.932 19l-1.154-.04a2 2 0 0 0-1.528.634l-.789.844a2 2 0 0 1-2.922 0l-.789-.844a2 2 0 0 0-1.528-.633L7.068 19A2 2 0 0 1 5 16.932l.04-1.154a2 2 0 0 0-.634-1.528l-.844-.789a2 2 0 0 1 0-2.922l.844-.789a2 2 0 0 0 .633-1.528L5 7.068A2 2 0 0 1 7.068 5l1.154.04a2 2 0 0 0 1.528-.634l.789-.844z" />
    <Circle cx={12} cy={12} r={3} />
  </Svg>
)

export const HomeIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Path d="M5 9v9a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V9" />
    <Path d="m3 10.5 8.36-6.967a1 1 0 0 1 1.28 0L21 10.5" />
  </Svg>
)

export const InfoIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Path d="M12 16v-4m0-3.99V8" />
    <Circle cx={12} cy={12} r={9} />
  </Svg>
)

export const RefreshIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <Path d="M13 6a6 6 0 0 1 4.313 10.172" />
    <Path d="m13 10-1.5-4.5 4-2.5M11 18A6 6 0 0 1 6.784 7.73" />
    <Path d="m11 14 1.5 4.5-4 2.5" />
  </Svg>
)
